<template>
    <v-card min-height="500px">
        <v-card-text>
            <ValidationObserver ref="observer" v-slot="{}">
                <v-form>
                    <!-- Email Info Section -->

                    <v-row dense>
                        <v-col>
                            <CustomLabel for="email" label="Email Address" />
                            <CustomTextField
                                id="email"
                                inputDisabled
                                inputIcon="mdi-email"
                                inputPlaceholder="Email Address"
                                inputType="email"
                                :inputValue="user.email"
                                validationName="email"
                                validationRules="required"
                            />
                        </v-col>
                    </v-row>

                    <!-- Divider -->
                    <v-row>
                        <v-col>
                            <v-divider />
                        </v-col>
                    </v-row>

                    <!-- Personal Info Section -->

                    <v-row dense>
                        <v-col cols="12" sm="4">
                            <CustomLabel
                                for="first_name"
                                label="First Name"
                                required
                            />
                            <CustomTextField
                                id="first_name"
                                inputPlaceholder="First Name"
                                :inputValue="user.first_name"
                                validationName="first_name"
                                validationRules="required"
                                @input="updateForm($event, 'user.first_name')"
                            />
                        </v-col>

                        <v-col cols="12" sm="4">
                            <CustomLabel
                                for="middle_name"
                                label="Middle Name"
                            />
                            <CustomTextField
                                id="middle_name"
                                inputPlaceholder="Middle Name"
                                inputType="text"
                                :inputValue="user.middle_name"
                                @input="updateForm($event, 'user.middle_name')"
                            />
                        </v-col>

                        <v-col cols="12" sm="4">
                            <CustomLabel
                                for="last_name"
                                label="Last Name"
                                required
                            />
                            <CustomTextField
                                id="last_name"
                                inputPlaceholder="Last Name"
                                inputType="text"
                                :inputValue="user.last_name"
                                validationName="last_name"
                                validationRules="required"
                                @input="updateForm($event, 'user.last_name')"
                            />
                        </v-col>
                    </v-row>

                    <v-row dense>
                        <v-col>
                            <v-row dense>
                                <v-col>
                                    <CustomLabel
                                        for="display_name"
                                        label="Display Name"
                                    />
                                    <CustomTextField
                                        id="display_name"
                                        inputPlaceholder="Display Name"
                                        inputType="text"
                                        :inputValue="user.display_name"
                                        validationName="display_name"
                                        validationRules="required"
                                        @input="
                                            updateForm(
                                                $event,
                                                'user.display_name'
                                            )
                                        "
                                    />
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                    <!-- Button Section -->
                    <v-row>
                        <v-col>
                            <CustomButton
                                btnLabel="Cancel"
                                tooltipMsg="Cancel"
                                @click="hideDialog"
                            />
                            <CustomButton
                                btnColour="primary"
                                :btnIsLoading="updateOps.isLoading"
                                btnLabel="Save"
                                tooltipMsg="Save"
                                @click="updateItem(user)"
                            />
                        </v-col>
                    </v-row>
                </v-form>
            </ValidationObserver>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapState } from "vuex";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";

extend("required", {
    ...required,
    message: "Field cannot be empty.",
});

export default {
    computed: {
        ...mapState("usersAdminMgmt", ["user", "updateOps"]),
    },
    data: () => ({}),
    methods: {
        hideDialog() {
            this.$store.commit("usersAdminMgmt/HIDE_VIEW_OPS_DIALOG");
        },
        updateForm(event, form) {
            if (event instanceof InputEvent) {
                this.$store.commit("usersAdminMgmt/UPDATE_FORM", {
                    payload: {
                        form: form,
                        value: event.target.value,
                    },
                });
                return;
            } else if (
                event instanceof String ||
                typeof event == "boolean" ||
                typeof event == "number" ||
                typeof event == "string"
            ) {
                this.$store.commit("usersAdminMgmt/UPDATE_FORM", {
                    payload: {
                        form: form,
                        value: event,
                    },
                });
                return;
            } else {
                return;
            }
        },
        async updateItem(payload) {
            let valid = this.$refs.observer.validate();

            if (!valid) {
                return;
            }

            let response = await this.$store.dispatch(
                "usersAdminMgmt/updateItem",
                {
                    id: payload.id,
                    user: {
                        email: payload.email,
                        first_name: payload.first_name,
                        middle_name: payload.middle_name,
                        last_name: payload.last_name,
                        display_name: payload.display_name,
                    },
                }
            );

            if (response) {
                this.$refs.observer.reset();
            }
        },
    },
    name: "TabUserProfile",
    // Lifecycle hooks.
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
};
</script>
